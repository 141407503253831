import gsap from "gsap";

window.onbeforeunload = function () { // force scroll top when loaded
  window.scrollTo(0, 0);
}

gsap.set(document.body, {overflow: "hidden"}) // Prevent scroll

gsap.set("header, .scrolltext, .hero__content h1, .hero__content p, .hero__content a", {
  opacity: 0
})

gsap.set("header", {
  y: -9
})

gsap.set(".loader__logo", {
  opacity: 0,
  y: -9
})

gsap.to(".loader__logo", {
  opacity: 1,
  y: 0,
  delay:.3,
  onComplete: () => {
    gsap.to(".loader__logo", {
      delay: .5,
      opacity: 0,
      duration: .5,
      onComplete: () => {
        initialLoadAnimation()
      }
    })
  }
})

const initialLoadAnimation = () => {
  gsap.to(".loader", {
    opacity: 0,
    delay: .5,
    duration: 2,
    onComplete: () => {
      gsap.set(".loader", {
        css: {zIndex: -9999}
      })
    }
  })

  gsap.to(".hero__content h1, .hero__content p, .hero__content a", {
    delay: 1.3,
    opacity: 1,
    scale: 1,
    y: 0,
    duration: 2,
    onComplete: () => {
      gsap.to("header", {
        delay: .3,
        y: 0,
        opacity: 1,
        duration: .7
      })
      gsap.to(".scrolltext", {
        delay: .3,
        opacity: 1,
        duration: .7
      })
      gsap.set(document.body, {overflow: "auto"}) // Enable scroll
    }
  })
}