import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger);

// SERVICES

gsap.set(".services h2, .services p", {
  opacity: 0,
  y: 0
});

gsap.set(".services__item", {
  opacity: 0,
  y: 18
});

gsap.to(".services h2, .services p, .services__item", {
  scrollTrigger: {
    trigger: ".services",
    start: "top center"
  },
  opacity: 1,
  y:0,
  ease: "sine.out",
  duration: 1, 
  stagger: .20
});

// PROCESS

gsap.set(".process h2, .process__breadcrumbs, .process p, .process li", {
  opacity: 0,
  y: 0
});

gsap.set(".process__visual", {
  opacity: 0,
  y: -18
});

gsap.to(".process h2, .process__breadcrumbs, .process p, .process li", {
  scrollTrigger: {
    trigger: ".process",
    start: "top center"
  },
  opacity: 1,
  y:0,
  ease: "sine.out",
  duration: 1, 
  stagger: .10
});

gsap.to(".process__visual", {
  scrollTrigger: {
    trigger: ".process",
    start: "top center"
  },
  opacity: 1,
  y:0,
  ease: "sine.out",
  duration: 1
});

// WHY

gsap.set(".why h2, .why .item", {
  opacity: 0,
});

gsap.set(".why .item", {
  y: 18
});

gsap.to(".why h2, .why .item", {
  scrollTrigger: {
    trigger: ".why",
    start: "top center"
  },
  opacity: 1,
  y:0,
  ease: "sine.out",
  duration: 1, 
  stagger: .20
});

gsap.set("footer h1, footer .button", {
  opacity: 0,
});

gsap.to("footer h1, footer .button", {
  scrollTrigger: {
    trigger: "footer",
    start: "top center"
  },
  opacity: 1,
  y:0,
  ease: "sine.out",
  duration: 1
});

// ABOUT

gsap.set(".about__content, .about h1, .about h2, .about p", {
  opacity: 0,
});

gsap.set(".about__content", {
  y: 18,
});

gsap.to(".about__content, .about h1, .about h2, .about p", {
  scrollTrigger: {
    trigger: ".about",
    start: "top center"
  },
  opacity: 1,
  y:0,
  ease: "sine.out",
  duration: 1
});

// FOOTER

gsap.set("footer h1, footer .button", {
  opacity: 0,
});

gsap.to("footer h1, footer .button", {
  scrollTrigger: {
    trigger: "footer",
    start: "top center"
  },
  opacity: 1,
  y:0,
  ease: "sine.out",
  duration: 1
});