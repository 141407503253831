import gsap from "gsap"

const menuHamburger = document.querySelector(".menu-icon")
const menuHamburgerItem = document.querySelectorAll(".menu__item")
const menuClose = document.querySelector(".close-icon")

gsap.set('.menu__item', {
  opacity: 0,
  y: 18
})

menuHamburger.addEventListener('click', () => {
  gsap.set(document.body, {overflow: "hidden"}) // Prevent scroll
  gsap.to(".menu", {
    opacity: 1,
    duration: .5,
    pointerEvents: "all"
  })
  gsap.to(".menu__item", {
    opacity: 1,
    y: 0,
    duration: .5,
    stagger: .05
  })
})

menuHamburgerItem.forEach(item => {
  item.addEventListener('click', () => {
    gsap.set(document.body, {overflow: "auto"}) // Enable scroll
    gsap.to(".menu", {
      delay: .3,
      opacity: 0,
      duration: .5,
      pointerEvents: "none"
    })
    gsap.to('.menu__item', {
      opacity: 0,
      y: -18,
      duration: .5,
      stagger: .05,
      onComplete: () => {
        gsap.set('.menu__item', {
          opacity: 0,
          y: 18
        })
      }
    })
  })
});

menuClose.addEventListener('click', () => {
  gsap.set(document.body, {overflow: "auto"}) // Enable scroll
  gsap.to(".menu", {
    opacity: 0,
    duration: .5,
    pointerEvents: "none"
  })
  gsap.to(".menu__item", {
    opacity: 0,
    y: 18,
    duration: .5,
    stagger: .05
  })
})

