import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin)

const serviceLink = document.getElementById("service-link");
serviceLink.addEventListener('click', () => {
  gsap.to(window, { duration: 1, scrollTo: ".services" });
})

const processLink = document.getElementById("process-link");
processLink.addEventListener('click', () => {
  gsap.to(window, { duration: 1, scrollTo: ".process" });
})

const whyLink = document.getElementById("why-link");
whyLink.addEventListener('click', () => {
  gsap.to(window, { duration: 1, scrollTo: ".why" });
})

// MENU

const menuServiceLink = document.getElementById("menu-service-link");
menuServiceLink.addEventListener('click', () => {
  gsap.to(window, { duration: 1, scrollTo: ".services" });
})

const menuProcessLink = document.getElementById("menu-process-link");
menuProcessLink.addEventListener('click', () => {
  gsap.to(window, { duration: 1, scrollTo: ".process" });
})

const menuWhyLink = document.getElementById("menu-why-link");
menuWhyLink.addEventListener('click', () => {
  gsap.to(window, { duration: 1, scrollTo: ".why" });
})